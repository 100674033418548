import React from 'react'
import Routes from './Routes'
import Header from './components/Header'
import Footer from './components/Footer'

const App = () => {
    const VERSION = '3.4.0'

    return (
        <div className='flex flex-col app bg-themeColorPrimary3 min-h-screen'>
            <Header />
            <Routes />
            <Footer />
            <div className='mt-auto ml-auto text-base font-semibold p-4 pr-6'>
                v{VERSION}
            </div>
        </div>
    )
}

export default App

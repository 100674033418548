import React, { useState, useRef, useEffect } from 'react'
import {
    Menu,
    X,
    Home,
    MessageSquare,
    User,
    LogOut,
    PlusCircle,
    ClipboardList,
} from 'lucide-react'
import Draggable from 'react-draggable'
import Logo from '../assets/logo.png'
import { Link, useLocation } from 'wouter'
import { isAdmin } from '../utils/helpers'
import { signOut } from 'firebase/auth'
import { auth } from '../config/firebaseConfig'
import { doc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import AdditionalFilesUpload from '../pages/AdditionalFilesUpload'
import { onAuthStateChanged } from 'firebase/auth'

const formatTimestamp = (timestamp) => {
    try {
        const date = new Date(timestamp)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    } catch (error) {
        return 'Invalid date'
    }
}

const MessageList = ({ messages, onMoveToRead, onUploadDocs, isUpdating }) => (
    <div className='overflow-y-auto max-h-[320px]'>
        {messages.length > 0 ? (
            messages
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .map((msg, index) => {
                    const isBrokerQuestion =
                        msg.message
                            .toLowerCase()
                            .includes('question for broker') ||
                        msg.message.toLowerCase().includes('broker action')

                    return (
                        <div
                            key={index}
                            className='p-3 border-b border-gray-100'
                        >
                            <div className='flex justify-between items-start mb-1'>
                                <span className='font-medium text-sm text-gray-900'>
                                    {msg.dealName}
                                </span>
                                <span className='text-xs text-gray-500'>
                                    {formatTimestamp(msg.timestamp)}
                                </span>
                            </div>
                            <p className='text-sm text-gray-600 mb-2'>
                                {msg.message}
                            </p>
                            {!msg.read &&
                                (isBrokerQuestion ? (
                                    <button
                                        onClick={() => onUploadDocs(msg)}
                                        disabled={isUpdating}
                                        className='w-full bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-medium py-1.5 px-3 rounded text-sm disabled:opacity-50 disabled:cursor-not-allowed'
                                    >
                                        Upload Docs
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => onMoveToRead(msg)}
                                        disabled={isUpdating}
                                        className='w-full bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-1.5 px-3 rounded text-sm disabled:opacity-50 disabled:cursor-not-allowed'
                                    >
                                        {isUpdating ? (
                                            <div className='flex items-center justify-center'>
                                                <div className='w-4 h-4 border-2 border-t-2 border-gray-200 rounded-full animate-spin mr-2' />
                                                Moving to Read...
                                            </div>
                                        ) : (
                                            'Move to Read'
                                        )}
                                    </button>
                                ))}
                        </div>
                    )
                })
        ) : (
            <div className='p-4 text-center text-gray-500'>No messages</div>
        )}
    </div>
)

const MessagePopup = ({
    messagePopupRef,
    isMessagePopupOpen,
    setIsMessagePopupOpen,
    activeTab,
    setActiveTab,
    unreadMessages,
    readMessages,
    handleMoveToRead,
    handleUploadDocs,
    isUpdating,
    position,
    onDragStop,
}) => {
    if (!isMessagePopupOpen) return null

    return (
        <Draggable
            handle='.drag-handle'
            bounds='body'
            position={position}
            onStop={onDragStop}
        >
            <div
                ref={messagePopupRef}
                className='fixed w-[350px] bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-[999]'
            >
                <div className='flex items-center justify-between p-3 border-b border-gray-200 bg-gray-50 drag-handle cursor-move'>
                    <h3 className='font-semibold text-gray-700'>Messages</h3>
                    <X
                        className='h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700'
                        onClick={() => setIsMessagePopupOpen(false)}
                    />
                </div>

                <div className='flex border-b border-gray-200'>
                    <button
                        className={`flex-1 py-2 px-4 text-sm font-medium ${
                            activeTab === 'unread'
                                ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('unread')}
                    >
                        Unread ({unreadMessages.length})
                    </button>
                    <button
                        className={`flex-1 py-2 px-4 text-sm font-medium ${
                            activeTab === 'read'
                                ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('read')}
                    >
                        Read ({readMessages.length})
                    </button>
                </div>

                <MessageList
                    messages={
                        activeTab === 'unread' ? unreadMessages : readMessages
                    }
                    onMoveToRead={handleMoveToRead}
                    onUploadDocs={handleUploadDocs}
                    isUpdating={isUpdating}
                />
            </div>
        </Draggable>
    )
}

const Header = () => {
    // State management
    const [user, setUser] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [loggedIn, setLoggedIn] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [location, navigate] = useLocation()
    const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('unread')
    const [isUpdating, setIsUpdating] = useState(false)
    const [selectedMessage, setSelectedMessage] = useState(null)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [currentDeal, setCurrentDeal] = useState(null)
    const [messagePosition, setMessagePosition] = useState(null)

    // Refs
    const sidebarRef = useRef(null)
    const messagePopupRef = useRef(null)
    const messageButtonRef = useRef(null)

    // Auth state management
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser)
                setLoggedIn(true)
                const userRef = doc(
                    db,
                    process.env.REACT_APP_USERS_DB_REF,
                    currentUser.uid
                )
                const unsubscribeUser = onSnapshot(userRef, (doc) => {
                    if (doc.exists()) {
                        setUserInfo(doc.data())
                    }
                })
                return () => unsubscribeUser()
            } else {
                setUser(null)
                setUserInfo(null)
                setLoggedIn(false)
            }
        })

        return () => unsubscribe()
    }, [])

    const getCurrentDealId = () => {
        const match = location.match(/\/deal\/modify\/(.+)/)
        return match ? match[1].split('?')[0] : null
    }

    useEffect(() => {
        const savedPosition = localStorage.getItem('messageBoxPosition')
        if (savedPosition) {
            setMessagePosition(JSON.parse(savedPosition))
        }
    }, [])

    const currentDealId = getCurrentDealId()

    useEffect(() => {
        const fetchDeal = async () => {
            if (currentDealId && showUploadModal) {
                try {
                    const dealRef = doc(
                        db,
                        process.env.REACT_APP_DEALS_DB_REF,
                        currentDealId
                    )
                    const dealSnap = await getDoc(dealRef)
                    if (dealSnap.exists()) {
                        setCurrentDeal(dealSnap.data())
                    }
                } catch (error) {
                    console.error('Error fetching deal:', error)
                }
            }
        }

        fetchDeal()
    }, [currentDealId, showUploadModal])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target)
            ) {
                setIsSidebarOpen(false)
            }
            if (
                messagePopupRef.current &&
                !messagePopupRef.current.contains(event.target) &&
                !messageButtonRef.current?.contains(event.target) &&
                !event.target.closest('.drag-handle')
            ) {
                setIsMessagePopupOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const handleLogout = async () => {
        try {
            await signOut(auth)
            setLoggedIn(false)
            setIsSidebarOpen(false)
            localStorage.removeItem('messageBoxPosition')
            navigate('/')
            // window.location.href = '/'
        } catch (error) {
            console.error('Error signing out:', error)
        }
    }

    const handleNavigation = (path) => {
        window.location = path
        setIsSidebarOpen(false)
    }

    const handleUploadComplete = async () => {
        if (selectedMessage) {
            await handleMoveToRead(selectedMessage)
        }
        setShowUploadModal(false)
        setSelectedMessage(null)
        window.location.reload()
    }

    const handleMoveToRead = async (message) => {
        if (isUpdating) return
        setIsUpdating(true)

        try {
            const userRef = doc(
                db,
                process.env.REACT_APP_USERS_DB_REF,
                user.uid
            )
            const userDoc = await getDoc(userRef)

            if (userDoc.exists()) {
                const updatedMessages = userDoc.data().messages.map((msg) => {
                    if (msg.id === message.id) {
                        return { ...msg, read: true }
                    }
                    return msg
                })

                await updateDoc(userRef, {
                    messages: updatedMessages,
                })
            }
        } catch (error) {
            console.error('Error updating message status:', error)
        } finally {
            setIsUpdating(false)
        }
    }

    const handleUploadDocs = (message) => {
        const url = `/deal/modify/${message.dealId}?messageId=${message.id}`
        navigate(url)
        setSelectedMessage(message)
        setShowUploadModal(true)
        setIsMessagePopupOpen(false)
    }

    const handleDragStop = (e, data) => {
        const newPosition = { x: data.x, y: data.y }
        setMessagePosition(newPosition)
        localStorage.setItem('messageBoxPosition', JSON.stringify(newPosition))
    }

    const getInitialPosition = () => {
        if (messagePosition) {
            return messagePosition
        }
        const messageIcon = messageButtonRef.current?.getBoundingClientRect()
        if (messageIcon) {
            return {
                x: messageIcon.right - 350,
                y: messageIcon.bottom + 10,
            }
        }
        return { x: window.innerWidth - 400, y: 100 }
    }

    const messages = userInfo?.messages || []
    const unreadMessages = messages.filter((msg) => !msg.read)
    const readMessages = messages.filter((msg) => msg.read)

    return (
        <>
            {loggedIn && user && (
                <div className='fixed w-full h-[35px] bg-themeColorPrimary px-[50px] flex items-center z-[999]'>
                    <p className='w-fit ml-auto text-white font-semibold'>
                        {isAdmin(user) ? (
                            <span className='text-white'>
                                You are logged in as <b>ADMIN</b>
                            </span>
                        ) : (
                            <>
                                {userInfo?.firstName && (
                                    <>
                                        Hello,{' '}
                                        <Link
                                            to='/profile'
                                            className='underline cursor-pointer'
                                        >
                                            <span className='text-white'>
                                                <b>
                                                    {`${userInfo?.firstName} ${userInfo?.lastName}` ||
                                                        'User'}
                                                </b>
                                            </span>
                                        </Link>{' '}
                                        {`(${userInfo?.companyName?.toUpperCase()})`}
                                    </>
                                )}
                            </>
                        )}
                    </p>
                </div>
            )}
            {/* Main header - always visible */}
            <div
                className='fixed top-0 left-0 h-18 w-full bg-white shadow-sm z-[998] px-4 py-4 flex items-center justify-between'
                style={{ top: loggedIn ? '35px' : '0' }}
            >
                <div className='flex items-center'>
                    {/* Only show menu button if logged in */}
                    {loggedIn && (
                        <button
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            className='p-2 text-themeColorPrimary hover:text-themeColorPrimary2 focus:outline-none'
                        >
                            <Menu className='h-8 w-8' />
                        </button>
                    )}
                    <Link to='/' className='ml-4'>
                        <img className='h-[60px]' src={Logo} alt='logo' />
                    </Link>
                </div>

                {/* Only show action buttons if logged in */}
                {loggedIn && (
                    <div className='flex items-center space-x-4'>
                        {!isAdmin(user) && (
                            <>
                                <div
                                    className='relative'
                                    ref={messageButtonRef}
                                >
                                    {unreadMessages.length > 0 && (
                                        <span className='absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center'>
                                            {unreadMessages.length}
                                        </span>
                                    )}
                                    <MessageSquare
                                        className='h-8 w-8 text-themeColorPrimary hover:text-themeColorPrimary2 cursor-pointer'
                                        onClick={() =>
                                            setIsMessagePopupOpen(
                                                !isMessagePopupOpen
                                            )
                                        }
                                    />
                                </div>
                                <button
                                    onClick={() =>
                                        handleNavigation('/create-deal')
                                    }
                                    className='bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded'
                                >
                                    Create Deal
                                </button>
                            </>
                        )}
                        <button
                            onClick={handleLogout}
                            className='bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded'
                        >
                            Logout
                        </button>
                    </div>
                )}
            </div>
            {/* Sidebar backdrop */}
            {loggedIn && (
                <div
                    className={`fixed inset-0 bg-black/50 z-[999] transition-opacity duration-200 ${
                        isSidebarOpen
                            ? 'opacity-100'
                            : 'opacity-0 pointer-events-none'
                    }`}
                    onClick={() => setIsSidebarOpen(false)}
                >
                    {/* Sidebar */}
                    <div
                        ref={sidebarRef}
                        className={`fixed top-0 left-0 h-full bg-white shadow-lg z-[1000] w-[280px] transition-transform duration-300 ease-out ${
                            isSidebarOpen
                                ? 'translate-x-0'
                                : '-translate-x-full'
                        }`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className='p-4 border-b border-gray-200 flex items-center justify-between'>
                            <Link to='/' className='flex-shrink-0'>
                                <img
                                    className='h-[60px]'
                                    src={Logo}
                                    alt='logo'
                                />
                            </Link>
                            <button
                                onClick={() => setIsSidebarOpen(false)}
                                className='p-2 text-gray-500 hover:text-gray-700 focus:outline-none'
                            >
                                <X className='h-5 w-5' />
                            </button>
                        </div>

                        <div className='py-4'>
                            {isAdmin(user) ? (
                                <div className='space-y-1'>
                                    <button
                                        onClick={() =>
                                            handleNavigation('/admin')
                                        }
                                        className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <Home className='h-5 w-5 text-gray-500' />
                                        <span>Home</span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleNavigation('/admin?tab=iso')
                                        }
                                        className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <ClipboardList className='h-5 w-5 text-gray-500' />
                                        <span>ISO</span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleNavigation(
                                                '/admin?tab=submissions'
                                            )
                                        }
                                        className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <ClipboardList className='h-5 w-5 text-gray-500' />
                                        <span>Submissions</span>
                                    </button>
                                </div>
                            ) : (
                                <div className='space-y-1'>
                                    <button
                                        onClick={() =>
                                            handleNavigation('/portal')
                                        }
                                        className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <Home className='h-5 w-5 text-gray-500' />
                                        <span>Home</span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleNavigation('/create-deal')
                                        }
                                        className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <PlusCircle className='h-5 w-5 text-gray-500' />
                                        <span>Create Deal</span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            setIsMessagePopupOpen(
                                                !isMessagePopupOpen
                                            )
                                        }
                                        className='messages-trigger w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <MessageSquare className='h-5 w-5 text-gray-500' />
                                        <span className='flex items-center'>
                                            Messages
                                            {unreadMessages.length > 0 && (
                                                <span className='ml-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center'>
                                                    {unreadMessages.length}
                                                </span>
                                            )}
                                        </span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleNavigation('/profile')
                                        }
                                        className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                    >
                                        <User className='h-5 w-5 text-gray-500' />
                                        <span>Profile</span>
                                    </button>
                                </div>
                            )}

                            <div className='border-t border-gray-200 mt-4 pt-4'>
                                <button
                                    onClick={handleLogout}
                                    className='w-full text-left px-4 py-3 text-gray-800 hover:bg-gray-100 flex items-center space-x-3 transition-colors duration-200'
                                >
                                    <LogOut className='h-5 w-5 text-gray-500' />
                                    <span>Logout</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Only show message popup if logged in and not admin */}
            {loggedIn && !isAdmin(user) && (
                <MessagePopup
                    messagePopupRef={messagePopupRef}
                    isMessagePopupOpen={isMessagePopupOpen}
                    setIsMessagePopupOpen={setIsMessagePopupOpen}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    unreadMessages={unreadMessages}
                    readMessages={readMessages}
                    handleMoveToRead={handleMoveToRead}
                    handleUploadDocs={handleUploadDocs}
                    isUpdating={isUpdating}
                    position={getInitialPosition()}
                    onDragStop={handleDragStop}
                />
            )}
            {loggedIn && showUploadModal && (
                <div className='fixed inset-0 z-[1001] bg-black bg-opacity-50 flex items-center justify-center'>
                    <div className='bg-white rounded-lg w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto'>
                        <div className='p-4 border-b border-gray-200 flex justify-between items-center'>
                            <h2 className='text-xl font-bold'>
                                Upload Additional Documents
                            </h2>
                            <button
                                onClick={() => setShowUploadModal(false)}
                                className='text-gray-500 hover:text-gray-700'
                            >
                                <X className='h-6 w-6' />
                            </button>
                        </div>
                        <div className='p-4'>
                            <AdditionalFilesUpload
                                dealId={currentDealId}
                                userId={userInfo.userId}
                                userEmail={user.email}
                                onUploadComplete={handleUploadComplete}
                                currentDeal={currentDeal}
                                selectedMessage={selectedMessage}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Header

import React, { useState, useEffect } from 'react'
import { Upload, AlertCircle } from 'lucide-react'
import axios from 'axios'
import { useLocation } from 'wouter'
import { doc, getDoc, setDoc, arrayUnion } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import moment from 'moment'
import { findUserByEmail } from '../utils/helpers'

const UploadBanner = ({ selectedMessage, onClose }) => {
    if (!selectedMessage) return null
    return (
        <div className='bg-[#CD2B2B] text-white rounded-t-lg'>
            <div className='w-full px-6 py-2'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center space-x-2'>
                        <AlertCircle className='h-5 w-5 flex-shrink-0' />
                        <span className='text-base font-normal'>
                            {selectedMessage.message}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AdditionalFilesUpload = ({
    dealId,
    userId,
    userEmail,
    onUploadComplete,
    currentDeal,
    selectedMessage,
}) => {
    // Get the last three years
    const getLastThreeYears = () => {
        const currentYear = new Date().getFullYear()
        return [currentYear - 1, currentYear - 2, currentYear - 3]
    }

    const lastThreeYears = getLastThreeYears()
    const lastYear = lastThreeYears[0] // For tax extensions and financial documents

    const [businessName, setBusinessName] = useState(
        currentDeal?.businessName || ''
    )
    const [files, setFiles] = useState({
        // Initialize files state with dynamic years
        ...lastThreeYears.reduce(
            (acc, year) => ({
                ...acc,
                [`taxReturn${year}`]: null,
                [`personalTaxReturn${year}`]: null,
            }),
            {}
        ),

        // Tax Extensions for last year
        [`businessTaxExtension${lastYear}`]: null,
        [`personalTaxExtension${lastYear}`]: null,

        // Financial documents for last year
        [`plStatement${lastYear}`]: null,
        [`accountsReceivable${lastYear}`]: null,
        [`accountsPayables${lastYear}`]: null,

        // Additional document arrays
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        businessTaxReturnsAdditional: [],
        personalTaxReturnsAdditional: [],
        taxExtensionsAdditional: [],
        miscellaneousAdditional: [],
    })
    const [uploading, setUploading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [showBanner, setShowBanner] = useState(!!selectedMessage)
    const [, navigate] = useLocation()
    const [uploadingStates, setUploadingStates] = useState({})

    const [dealUser, setDealUser] = useState({})

    const MAX_FILE_SIZE = 50 * 1024 * 1024 // 50MB in bytes

    useEffect(() => {
        setShowBanner(!!selectedMessage)
    }, [selectedMessage])

    useEffect(() => {
        if (currentDeal?.businessName) {
            setBusinessName(currentDeal.businessName)
        }
    }, [currentDeal])

    useEffect(() => {
        if (currentDeal?.userEmail) {
            findUserByEmail(currentDeal.userEmail).then((user) => {
                setDealUser(user)
            })
        }
        // eslint-disable-next-line
    }, [currentDeal])

    useEffect(() => {
        const fetchBusinessName = async () => {
            if (!businessName) {
                try {
                    const dealRef = doc(
                        db,
                        process.env.REACT_APP_DEALS_DB_REF,
                        dealId
                    )
                    const dealSnap = await getDoc(dealRef)
                    if (dealSnap.exists()) {
                        const dealData = dealSnap.data()
                        setBusinessName(dealData.businessName)
                    }
                } catch (error) {
                    console.error('Error fetching business name:', error)
                }
            }
        }
        fetchBusinessName()
    }, [dealId, businessName])

    const isMultipleFileCategory = (category) => {
        return [
            'bankStatementsAdditional',
            'applicationsAdditional',
            'taxReturnsAdditional',
            'miscellaneousAdditional',
            'businessTaxReturnsAdditional',
            'personalTaxReturnsAdditional',
            'taxExtensionsAdditional',
        ].includes(category)
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !(file instanceof Blob)) {
                resolve('N/A')
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const handleFileChange = async (category, files) => {
        try {
            setUploadingStates((prev) => ({ ...prev, [category]: true }))

            const validFiles = Array.from(files).filter((file) => {
                if (file.size > MAX_FILE_SIZE) {
                    alert(`File "${file.name}" exceeds 50MB size limit`)
                    return false
                }
                return true
            })

            if (validFiles.length === 0) {
                setUploadingStates((prev) => ({ ...prev, [category]: false }))
                return
            }

            const fileData = await Promise.all(
                validFiles.map(async (file) => ({
                    name: category,
                    content: await fileToBase64(file),
                    type: file.type,
                    originalName: file.name,
                }))
            )

            // Upload to Firebase Storage through backend
            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/uploadFile',
                // 'http://127.0.0.1:5001/quidity-app/us-central1/uploadFile',
                {
                    file: fileData[0], // For single file upload
                    files: isMultipleFileCategory(category) ? fileData : null, // For multiple files
                    userId,
                    docId: dealId,
                    fileCategory: category,
                    isUserRegistration: false,
                }
            )

            if (!isMultipleFileCategory(category)) {
                const fileInfo = {
                    timestamp: new Date().toISOString(),
                    url: response.data.fileUrl,
                    originalName: validFiles[0].name,
                }
                setFiles((prev) => ({
                    ...prev,
                    [category]: fileInfo,
                }))

                // Update Firebase document
                await setDoc(
                    doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                    {
                        [category]: fileInfo,
                        lastUpdated: new Date().toISOString(),
                    },
                    { merge: true }
                )
            } else {
                const newFiles = validFiles.map((file, index) => ({
                    url: response.data.fileUrl,
                    timestamp: new Date().toISOString(),
                    originalName: file.name,
                }))

                setFiles((prev) => ({
                    ...prev,
                    [category]: [...(prev[category] || []), ...newFiles],
                }))

                // Update Firebase document for additional files
                await setDoc(
                    doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                    {
                        [category]: arrayUnion(...newFiles),
                        lastUpdated: new Date().toISOString(),
                    },
                    { merge: true }
                )
            }

            // Send notifications about new document upload
            await axios.post('https://eo5udo9oq3rjgqi.m.pipedream.net', {
                textContent: `New Docs have been uploaded for ${businessName}`,
            })

            const isoName = dealUser.companyName
            const isoContact = dealUser.telephone

            await axios.post('https://eo3g4kfpldaav1h.m.pipedream.net', {
                businessName: businessName,
                isoEmail: dealUser.email,
                isoName,
                isoContact,
                date: `${moment().format('MM/DD/YYYY')}`,
            })
        } catch (error) {
            console.error('Error uploading file:', error)
            alert('File upload failed. Please try again.')
        } finally {
            setUploadingStates((prev) => ({ ...prev, [category]: false }))
        }
    }

    const removeFile = (category, index) => {
        if (isMultipleFileCategory(category)) {
            setFiles((prev) => ({
                ...prev,
                [category]: prev[category].filter((_, i) => i !== index),
            }))
        } else {
            setFiles((prev) => ({
                ...prev,
                [category]: null,
            }))
        }
    }

    const handleCloseBanner = () => {
        setShowBanner(false)
        if (onUploadComplete) {
            onUploadComplete()
        }
    }

    const handleSubmit = async () => {
        setUploading(true)
        setError('')
        setSuccess('')

        try {
            setSuccess('Files uploaded successfully!')
            if (onUploadComplete) {
                onUploadComplete()
            }

            setTimeout(() => {
                navigate(`/deal/modify/${dealId}`)
            }, 1500)
        } catch (err) {
            setError('Error uploading files. Please try again.')
            console.error('Upload error:', err)
        } finally {
            setUploading(false)
        }
    }

    const hasFiles = Object.entries(files).some(([category, fileOrFiles]) =>
        isMultipleFileCategory(category)
            ? fileOrFiles.length > 0
            : fileOrFiles !== null
    )

    const FileUploadSection = ({
        title,
        category,
        accept,
        multiple = false,
    }) => {
        const [isDragging, setIsDragging] = useState(false)
        const [showAcceptedTypes, setShowAcceptedTypes] = useState(false)
        const isUploading = uploadingStates[category]

        const handleDragOver = (e) => {
            e.preventDefault()
            setIsDragging(true)
        }

        const handleDragLeave = () => {
            setIsDragging(false)
        }

        const handleDrop = (e) => {
            e.preventDefault()
            setIsDragging(false)
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (droppedFiles.length > 0) {
                handleFileChange(category, droppedFiles)
            }
        }

        const handleInputChange = (e) => {
            handleFileChange(category, e.target.files)
        }

        const currentFiles = files[category]
        const hasFile = multiple
            ? currentFiles.length > 0
            : currentFiles !== null

        return (
            <div className='mb-4'>
                <div className='flex justify-between items-center mb-2'>
                    <label className='block font-medium text-gray-700'>
                        {title}
                    </label>
                    <div
                        className='text-xs text-gray-500 cursor-help'
                        onMouseEnter={() => setShowAcceptedTypes(true)}
                        onMouseLeave={() => setShowAcceptedTypes(false)}
                    >
                        {showAcceptedTypes && (
                            <div className='absolute bg-white shadow-lg rounded-md p-2 z-10'>
                                Accepted types: {accept}
                            </div>
                        )}
                        Accepted file types
                    </div>
                </div>

                <div className='flex flex-col space-y-2'>
                    {!hasFile && (
                        <div
                            className={`hidden md:flex lg:flex items-center border border-dashed rounded-md transition-colors duration-200 h-10 ${
                                isDragging
                                    ? 'border-themeColorPrimary bg-blue-50'
                                    : 'border-gray-300 hover:border-gray-400'
                            }`}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <div className='flex items-center px-3 flex-1'>
                                <Upload className='w-4 h-4 text-gray-400 mr-2' />
                                <span className='text-sm text-gray-600'>
                                    {isUploading
                                        ? 'Uploading...'
                                        : 'Drop file(s) here'}
                                </span>
                            </div>
                            <label className='cursor-pointer px-4 h-full flex items-center border-l border-gray-200 bg-gray-50 text-sm font-medium text-gray-700 hover:bg-gray-100'>
                                Browse
                                <input
                                    type='file'
                                    className='hidden'
                                    multiple={multiple}
                                    onChange={handleInputChange}
                                    accept={accept}
                                    disabled={isUploading}
                                />
                            </label>
                        </div>
                    )}

                    <div className='space-y-1'>
                        {multiple
                            ? currentFiles.map((file, index) => (
                                  <div
                                      key={index}
                                      className='flex items-center justify-between text-sm bg-gray-50 p-2 rounded-md'
                                  >
                                      <span className='truncate max-w-xs'>
                                          {file.originalName}
                                      </span>
                                      <button
                                          onClick={() =>
                                              removeFile(category, index)
                                          }
                                          className='text-red-600 hover:text-red-800 font-medium ml-2 text-xs'
                                      >
                                          Remove
                                      </button>
                                  </div>
                              ))
                            : currentFiles && (
                                  <div className='flex items-center justify-between text-sm bg-gray-50 p-2 rounded-md'>
                                      <span className='truncate max-w-xs'>
                                          {currentFiles.originalName}
                                      </span>
                                      <button
                                          onClick={() => removeFile(category)}
                                          className='text-red-600 hover:text-red-800 font-medium ml-2 text-xs'
                                      >
                                          Remove
                                      </button>
                                  </div>
                              )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='flex flex-col'>
            {showBanner && (
                <UploadBanner
                    selectedMessage={selectedMessage}
                    onClose={handleCloseBanner}
                />
            )}

            <div className='bg-white rounded-lg shadow p-6'>
                <h3 className='text-lg font-medium text-gray-900 mb-6'>
                    Business Name:{' '}
                    <span className='font-semibold'>
                        {businessName || 'Loading...'}
                    </span>
                </h3>

                <div className='space-y-6'>
                    {/* Bank Statements Section */}
                    <div>
                        <h4 className='text-lg font-bold mb-4'>
                            Bank Statements
                        </h4>
                        <FileUploadSection
                            title='Upload Bank Statements'
                            category='bankStatementsAdditional'
                            accept='.jpg,.jpeg,.png,.pdf'
                            multiple={true}
                        />
                    </div>

                    {/* Applications Section */}
                    <div>
                        <h4 className='text-lg font-bold mb-4'>Applications</h4>
                        <FileUploadSection
                            title='Upload Additional Applications'
                            category='applicationsAdditional'
                            accept='.jpg,.jpeg,.png,.pdf'
                            multiple={true}
                        />
                    </div>

                    <div>
                        <h4 className='text-lg font-bold mb-4'>Tax Returns</h4>
                    </div>

                    {/* Business Tax Returns Section */}
                    <div>
                        <h5 className='text-md underline font-bold mb-4'>
                            Business Tax Returns
                        </h5>
                        {lastThreeYears.map((year) => (
                            <>
                                {/* {`businessTaxReturn${year}`} */}
                                <FileUploadSection
                                    key={`business-tax-${year}`}
                                    title={`${year} Business Tax Return`}
                                    category={`taxReturn${year}`}
                                    accept='.jpg,.jpeg,.png,.pdf'
                                />
                            </>
                        ))}
                        <FileUploadSection
                            title='Additional Business Tax Returns'
                            category='businessTaxReturnsAdditional'
                            accept='.jpg,.jpeg,.png,.pdf'
                            multiple={true}
                        />
                    </div>

                    {/* Personal Tax Returns Section */}
                    <div>
                        <h5 className='text-md underline font-bold mb-4'>
                            Personal Tax Returns
                        </h5>
                        {lastThreeYears.map((year) => (
                            <FileUploadSection
                                key={`personal-tax-${year}`}
                                title={`${year} Personal Tax Return`}
                                category={`personalTaxReturn${year}`}
                                accept='.jpg,.jpeg,.png,.pdf'
                            />
                        ))}
                        <FileUploadSection
                            title='Additional Personal Tax Returns'
                            category='personalTaxReturnsAdditional'
                            accept='.jpg,.jpeg,.png,.pdf'
                            multiple={true}
                        />
                    </div>

                    {/* Tax Extensions Section */}
                    <div>
                        <h4 className='text-lg font-bold mb-4'>
                            Tax Extensions
                        </h4>
                        <FileUploadSection
                            title={`${lastYear} Business Tax Extension`}
                            category={`businessTaxExtension${lastYear}`}
                            accept='.jpg,.jpeg,.png,.pdf'
                        />
                        <FileUploadSection
                            title={`${lastYear} Personal Tax Extension`}
                            category={`personalTaxExtension${lastYear}`}
                            accept='.jpg,.jpeg,.png,.pdf'
                        />
                        <FileUploadSection
                            title='Additional Tax Extensions'
                            category='taxExtensionsAdditional'
                            accept='.jpg,.jpeg,.png,.pdf'
                            multiple={true}
                        />
                    </div>

                    {/* Miscellaneous Documents Section */}
                    <div>
                        <h4 className='text-lg font-bold mb-4'>
                            Miscellaneous Documents
                        </h4>
                        <FileUploadSection
                            title={`${lastYear} P&L Statement`}
                            category={`plStatement${lastYear}`}
                            accept='.jpg,.jpeg,.png,.pdf'
                        />
                        <FileUploadSection
                            title={`${lastYear} Accounts Receivable`}
                            category={`accountsReceivable${lastYear}`}
                            accept='.jpg,.jpeg,.png,.pdf'
                        />
                        <FileUploadSection
                            title={`${lastYear} Accounts Payables`}
                            category={`accountsPayables${lastYear}`}
                            accept='.jpg,.jpeg,.png,.pdf'
                        />
                        <FileUploadSection
                            title='Additional Miscellaneous Documents'
                            category='miscellaneousAdditional'
                            accept='.jpg,.jpeg,.png,.pdf'
                            multiple={true}
                        />
                    </div>
                </div>

                {!uploading && hasFiles && (
                    <button
                        onClick={handleSubmit}
                        disabled={!hasFiles || uploading}
                        className={`mb-4 w-full py-2 px-4 rounded-md text-white font-medium ${
                            hasFiles && !uploading
                                ? 'bg-themeColorPrimary hover:bg-themeColorPrimary2'
                                : 'bg-gray-300 cursor-not-allowed'
                        }`}
                    >
                        {uploading ? 'Uploading...' : 'Done'}
                    </button>
                )}

                {error && (
                    <div className='p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-md mt-6'>
                        {error}
                    </div>
                )}

                {success && (
                    <div className='p-3 mb-4 text-sm text-green-700 bg-green-100 rounded-md mt-6'>
                        {success}
                    </div>
                )}
            </div>
        </div>
    )
}

export default AdditionalFilesUpload
